<!--
 * @Descripttion: 个人中心
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-01 11:54:58
 * @LastEditTime: 2021-09-30 20:55:11
-->
<template>
  <div style="background: #fff;min-height:60vh">
    <div div
         class="breadcrumb">

      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>

    <!-- PC菜单栏 -->
    <el-row class="tac"
            ref="row_left"
            style="height:100%">
      <el-col :span="12"
              class="mHidden"
              style="width:16%;min-width:12vh;">
        <PcMenu />

        <!-- 菜单栏结束 -->
      </el-col>
      <el-col :span="12"
              class="c_div">
        <MobiMenu />
        <!-- 内容 -->
        <router-view></router-view>

      </el-col>
    </el-row>

  </div>

</template>
  
<script>
import PcMenu from "@/components/PcMenu.vue";
import MobiMenu from "@/components/MobiMenu.vue";
export default {
  name: "DbgamewebPerson",

  components: {
    PcMenu,
    MobiMenu,
  },

  directives: {},

  data() {
    return {
      width: 0,
    };
  },

  mounted() {
    
    let width = this.$refs.row_left.$el.clientWidth;
    this.width = width;
   // console.log(width);
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.c_div {
  width: 81%;
}
@media screen and (max-width: 880px) {
  .c_div {
    width: 100%;
  }
}
</style>