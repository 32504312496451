<!--
 * @Descripttion: 手机菜单
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-01 16:16:43
 * @LastEditTime: 2021-10-05 16:28:05
-->
<template>
  <div>
    <el-button @click="drawer = true"
               modal-append-to-body="true"
               type="primary"
               class="el-icon-s-fold pcHidden"
               style="
    position: fixed;
    top: 10vh;
">
      菜单
    </el-button>
    <el-drawer title="犀利担保平台"
               :visible.sync="drawer"
               :direction="direction"
               :before-close="handleClose"
               :modal-append-to-body="false"
               size="50%">
      <span>
        <PcMenu @closeDrawer="closeDrawer" />
      </span>
    </el-drawer>
  </div>
</template>

<script>
import PcMenu from "@/components/PcMenu.vue";
export default {
  name: "DbgamewebMobimenu",
  components: { PcMenu },
  directives: {},
  data() {
    return {
      drawer: false,
      direction: "ltr",
    };
  },
  mounted() {
   // console.log(this.drawer);
  },
  methods: {
    closeDrawer() {
      this.drawer = false;
    },
    handleClose(done) {
      done();
    },
  },
};
</script>

<style lang="scss" scoped>
</style>