<!--
 * @Descripttion: PC菜单
 * @version: DBNetGameManagement_v1.0
 * @Author: jerry_huang
 * @Date: 2021-09-01 15:53:09
 * @LastEditTime: 2021-09-30 03:52:47
-->
<template>
    <div>
         <el-menu
      background-color="#fff"
      :default-active="this.$route.path"
      class="el-menu-vertical-demo"
      @select="handleSelect"
     
      router
      >
      <el-menu-item index="/member/person/memberinfo">
        <i class="el-icon-user-solid"></i>
        <span slot="title">个人信息</span>
      </el-menu-item>
      <el-submenu index="">
        <template slot="title">
          <i class="el-icon-s-shop"></i>
          <span>我要卖</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/member/person/MemberGameRole">我要卖账号</el-menu-item>
           <el-menu-item index="/member/person/MemberEquip">我要卖装备</el-menu-item>
        </el-menu-item-group>
      </el-submenu>

      <el-menu-item index="/member/person/memberfavorite">
        <i class="el-icon-folder"></i>
        <span slot="title">收藏夹</span>
      </el-menu-item>
    </el-menu> 
    </div>
</template>

<script>
export default {
    components: {  },

    directives: {  },

    data() {
        return {
            drawer:this.pDrawer
        };
    },

    mounted() {
        
    },

    methods: {
          handleSelect() {
            //   key, keyPath
         this.$emit('closeDrawer')
     
      },
     
    },
};
</script>

<style lang="scss" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {

    min-height: 60vh;
    .el-menu-item{
        padding: 0;
        min-width:100px;
    }
  }

</style>